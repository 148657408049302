import React from 'react';
import "./App.css"
import { AsyncImage } from 'loadable-image'


const App = () => {
  return (
    <div>
      <header>
        <nav className="navbar navbar-expand-lg navbar-dark">
          <div className="container-fluid">
            <div className="collapse navbar-collapse">
              <ul className="navbar-nav mx-auto">
                <li className="nav-item">
                  <a className="nav-link" href="#home">Home</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#what-is-avigate">About</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#contact">Contact</a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>

      <section id="home" className="section">
        <div className="container d-flex">
          <div className="left-section">
            <h2>Change is long overdue</h2>
            <p>Avigate is getting ready to AI-define how you shop, book and manage flights & ancillaries; across GDSs, NDC and LCCs .</p>
            <h6>Be the First to Know when we launch</h6>
            <button
              className="btn-action"
              onClick={() => window.location.href = 'mailto:help@avigate.ai?subject=Avigate%20Enquiry&body=Hi,%20I would%20like%20to%20know%20more%20about%20your%20product%20Avigate'}
            >
              <span>Book a demo <i className="bi bi-arrow-right"></i></span>
            </button>
          </div>
          <div className="right-section">
            {/* <img loading='lazy' src="/assets/right.png" alt="OverviewImage" className="img-fluid" /> */}
            <AsyncImage
  src="/assets/right.png"
  alt="OverviewImage"
  style={{
    width: '100%',
    height: 'auto',
    aspectRatio: 16 / 12,
    top: '23.5px',
  }}
/>

          </div>
        </div>
      </section>

      <section id="what-is-avigate" className="section">
        <div className="container d-flex">
          <div className="left-section">
            <h1>What is Avigate?</h1>
            <p>Informed customer is essential for any business’s success.</p>
            <p>We’re here to ensure your customers enjoy a satisfying pre and post-booking experience by providing complete awareness on flight and ancillaries options, pricing, unique features, and modification policies.</p>
            <p>Avigate is a leading AI-powered travel aggregation solution that centralizes airline supply, ancillary services, and content.</p>
          </div>
          <div className="right-section hidden-sm-down">
            {/* <img loading='lazy' src='/assets/what-right.png' alt="DashboardImage" className="img-fluid"  /> */}
            <AsyncImage
  src='/assets/what-right.png'
  alt="DashboardImage" 
  style={{
    width: '100%',
    height: 'auto', 
    aspectRatio: 15.3/10.09 , 
      }}
/>
          </div>
        </div>
      </section>

      <section id="banner" className="section">
        <div>
          <h1>One API</h1>
          <p>A single SAS API solution with a simple integration to access hundreds of airlines, across GDSs, NDC and LCCs.</p>
          <p>A centralised platform to assess business opportunities, payment reconciliations, commissions, and refunds across different airlines and booking systems in easier and more transparent manner</p>
        </div>
      </section>

      <section id="contact" className="section">
        <div className="container d-flex">
          <div className="w-50 px-2">
            <h3>About Us</h3>
            <p>We are a team of seasoned and innovative travel-tech experts dedicated to revolutionising the travel space through AI-driven, user first solutions. Our mission is to redefine (or, as we say within the company—AI-define) industry; enabling intelligent, seamless, and elevated travel experiences at every touchpoint.</p>
          </div>
          <div className="w-25 px-2">
            <h5>Contact Us</h5>
            <p><i className="bi bi-envelope"></i><a href="mailto:help@avigate.ai">help@avigate.ai</a></p>

            <div>
              <h6>UAE</h6>
              <p><i className="bi bi-telephone-fill"></i><a href="tel:+97145298980">(+971) 45298980</a></p>
            </div>
            <div>
              <h6>India</h6>
              <p><i className="bi bi-telephone-fill"></i><a href="tel:+911244370864">(+91) 124-4370864</a></p>
              <p><i className="bi bi-phone"></i><a href="tel:+917303738884">(+91) 73037-38884</a></p>
            </div>
          </div>

          <div className="w-25 px-2">
            <h5>Address</h5>
            <div>
              <h6>UAE</h6>
              <div className="d-flex">
                <i className="bi bi-geo-alt"></i>
                <div>
                  <span>1917, Tamani Arts Building, Business Bay, Dubai</span>
                </div>
              </div>
            </div>
            <div>
              <h6>India</h6>
              <div className="d-flex">
                <i className="bi bi-geo-alt"></i>
                <div>
                  <span>2, Emaar Capital Tower 1, MG Road, Sector 26, Gurugram, Haryana 122002</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default App;
